import React from "react";

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ stickyHeader, children }) => {
    
    return (
        <div id="wrapper">
            <Header stickyHeader={stickyHeader} />
            {children}
            <Footer />
        </div>
    );
}

export default Layout;