import React from "react";
import { StaticQuery, graphql } from "gatsby"


const Footer = ({ title }) => {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 marg50">
                        <StaticQuery 
                            query={
                                graphql`
                                    query {
                                        allContentfulHome {
                                            nodes {
                                                footerTitle,
                                            }
                                        }
                                    }
                                `
                            }
                            render={data => (
                                <div className="footer-promo">
                                  <h2>{data.allContentfulHome.nodes[0].footerTitle}</h2>
                                </div>
                              )}
                        />
                        <div className="footer-soc">
                            <div className="arrow-top">
                                <a href="#" className="scroll">
                                    <i className="icon-arrow-up"></i>
                                </a>
                            </div>
                        </div>
                        <div className="copyright">
                            Copyright © {new Date().getFullYear()} TACL. <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;