import React from "react";
import { Link } from "gatsby";

import StickyHeader from 'react-sticky-header';

import 'react-sticky-header/styles.css';

const Header = ({ stickyHeader }) => {

    let header = (
        <div className="subMenu">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 pull-left">
                        <Link to="/">
                            <div className="logo">
                                <img src={"../../images/logo-big.png"} width="193" height="52" alt="TACL logo" />
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 pull-right">
                        <div className="inner">
                            <div id="dl-menu" className="dl-menuwrapper"><button className="dl-trigger">Open Menu</button>
                                <ul className="dl-menu">
                                    <li><Link to="#services" id="services-link" className="subNavBtn">Services</Link></li>
                                    <li><Link to="#about" id="about-link" className="subNavBtn">About Us</Link></li>
                                    <li><Link to="#team" id="team-link" className="subNavBtn">Our Team</Link></li>
                                    <li><Link to="#contact" id="contact-link" className="subNavBtn">Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    if (stickyHeader) {
        header = (<StickyHeader header={header} />);
    }

    return header;
};

export default Header;